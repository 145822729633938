import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getNftDetailsAction, getcategorylistAction, getnfttypelistAction, updateNftAction, getWhitelistNFTWalletListAction, deletewhitelistAction, insertwalletAction, updateMetadataAction,getcitieslistAction } from "../Action/action";
import { useParams } from "react-router-dom";
import ReactDatatable from "@ashvin27/react-datatable";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from 'react-modal';

const EditNFT = () => {
    const { id } = useParams();
    const [form, setForm] = useState({ name: "" });
    const [validatioError, setvalidatioError] = useState({});
    const [Category, setCategory] = useState([]);
    const [Cities, setCities] = useState([]);   
    const [Types, setTypes] = useState([]);
    const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
    const [FileType, setFileType] = useState('');
    const [spinloader, setspinloader] = useState(0);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [NftDetails, setNftDetails] = useState({});
    const [WhitelistNftWalletList, setWhitelistNFTWalletList] = useState({});
    const [currentDate, setcurrentDate] = useState(new Date());
    const [isaddWALLET, setisAddWallet] = useState(0);
    const [addwallet, setAddWallet] = useState('');
    const [accuracyloader, setaccuracyloader] = useState(1);
    const [rateloader, setrateloader] = useState(1);
    const [blastloader, setblastloader] = useState(1);
    const [speedloader, setspeedloader] = useState(1);
    const [weaponloader, setweaponloader] = useState(1); 

    useEffect(() => {
        getCategoryAPI();
        getNFTDetailsAPI();
        getNFTtypeAPI()
        getCitiesAPI()
        getWhitelistNFTWalletListAPI();
    }, [])

    const getCategoryAPI = async () => {
        let res = await getcategorylistAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
     const getNFTtypeAPI = async () => {
        let res = await getnfttypelistAction();
        if (res.success) {
            setTypes(res.data)
        }
    }
      const getCitiesAPI = async () => {
        let res = await getcitieslistAction();
        if (res.success) {
            setCities(res.data)
        }
    }

    const getNFTDetailsAPI = async () => {
        let res = await getNftDetailsAction({ 'id': id });
        if (res.success) {
            setNftDetails(res.data)
            setcatattributeData(res.data.category_id)
        }
    }
    const getWhitelistNFTWalletListAPI = async () => {
        let res = await getWhitelistNFTWalletListAction({ 'id': id });
        if (res.success) {
            setWhitelistNFTWalletList(res.data)
        }
    }

    const imageUpload = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        let file_type = '';
        if (image_as_files.type.indexOf('image') === 0) {
            file_type = 'image';
        } else {
            file_type = 'video';
        }

        setimage_file(image_as_files);
        setimage_preview(image_as_base64);
        setFileType(file_type);
        setvalidatioError((old) => {
            return { ...old, ['imageError']: '' }
        })
    }

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setNftDetails((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }
    
    
    const metaDataUpload = async (fileHash) => {
       let attributes 	 = {} ;      
	      
       let reqData = {
            "name": NftDetails.title,
            "description": NftDetails.description,
            "image": `https://ipfs.io/ipfs/${fileHash}`
        }
        let resIPF = await updateMetadataAction(reqData);
        if (resIPF.status) {
           return NftDetails.token_id;
        } else {
            toast.error('Something went wrong creating metadata.');
            setspinloader(0);
            return false;
        }
    }

   

    function validate() {
        let titleError = "";
        let descriptionError = "";        
        let priceError = "";
        let bnb_priceError = "";      
        let imageError = "";      

        if (NftDetails?.title === '') {
            titleError = "Title field is required."
        }
        if (NftDetails?.description === '') {
            descriptionError = "Description field is required."
        }
       
        if (NftDetails?.price == 0) {
            priceError = "Price field is required."
        }
        if (image_file == '' && NftDetails?.image == "") {
            imageError = "Image field is required."
        } 
        
        if (titleError || descriptionError || priceError || bnb_priceError || imageError) {
            setvalidatioError({
                titleError, descriptionError, priceError,bnb_priceError, imageError})
            return false
        } else {
            return true
        }
    }
	
	function setcatattributeData(cat)
	{
		if(cat ==1)
       {
		    setaccuracyloader(0)
			setrateloader(0)
			setblastloader(1)
			setspeedloader(1)
			setweaponloader(1)
	   } 
	   else if(cat ==2)
       {
		    setweaponloader(0)
		    setspeedloader(0)
			setaccuracyloader(1)
			setrateloader(1)
			setblastloader(1)
			
			
	   } 
	   else if(cat ==3  || cat==4 )
       {
		   setaccuracyloader(0)
		   setblastloader(0)
		   setweaponloader(1)
		   setspeedloader(1)
		   setrateloader(1)
			
	   } 
	   else if(cat ==5 || cat ==6 || cat ==7 )
       {
		   setaccuracyloader(0)
		   setrateloader(0)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)
		   
	   } 
	   else
	   {
		   setaccuracyloader(1)
		   setrateloader(1)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)  
	   }
	}
	
    const updateNFt = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setspinloader(1);

            let fileHash = "";
            if (image_file) {
                fileHash = await imageUploadOnPinata();
                NftDetails.file_type = FileType;
            } else {
                fileHash = NftDetails?.image;
                NftDetails.file_type = NftDetails?.file_type;
            }

            if (fileHash) {
                NftDetails.ipfsHash = fileHash;
                let tokenId = await metaDataUpload(fileHash);
                if (tokenId) {
					let res = await updateNftAction(NftDetails);
					if (res.success) {
						toast.success(res.msg);
						setTimeout(() => {
							//window.location.href = `${config.baseUrl}adminnftslist`;
							window.location.reload();
						}, 2000);
					} else {
						toast.error(res.msg);
					}
				}
				else {
                    setspinloader(0);
                    toast.error('Something went wrong for creating metadata.');
                }
            } else {
                setspinloader(0);
                toast.error('Something went wrong for uploading image on pinata.');
            }
        }
    }

    const imageUploadOnPinata = async () => {
        let formData = new FormData();
        formData.append('file', image_file);
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let resIPF = await axios.post(url,
            formData,
            {
                headers: {
                    'Content-Type': `multipart/form-data; boundary= ${formData._boundary}`,
                     'pinata_api_key': '7ca3b2043a2bf4cdeed0',
                    'pinata_secret_api_key': 'e058c8d5654ca0dc7adc1bba93aae1986d961882c9a8eda11ded091b28a6b5e0'
                }
            }
        );
        if (resIPF.data.IpfsHash) {
            let ipfsImg = resIPF.data.IpfsHash;
            return ipfsImg;
        } else {
            toast.error('Something went wrong uploading image on IPFS.');
            setspinloader(0);
            return false;
        }
    }

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setNftDetails((old) => {
            return { ...old, ['start_date']: startDate }
        })
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setNftDetails((old) => {
            return { ...old, ['expiry_date']: expiryDate }
        })
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }

    const addWalletModelAPI = async () => {
		setisAddWallet(1);		
    }

    const closeWalletModel = async () => {
        setisAddWallet(0);
    }

    const addWALLET = async (id) => {
        if(addwallet !='' && addwallet !=0)
        {
            setspinloader(1);
            setDialogOpen(true);
            let walletdata = { nft_id:id,wallet:addwallet }
            let res = await insertwalletAction(walletdata);
            if (res.success) { 
                setDialogOpen(false);
                setspinloader(0);
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
        } 
        else
        {
            setspinloader(0);
            setisAddWallet(0);
            setDialogOpen(false);
            return false;
        } 
    }else{
        toast.error("Wallet address required.");
    }

}

	const inputCatHandler = e => {
        const { name, value, id } = e.target
        setNftDetails((old) => {
            return { ...old, [name]: value }
        })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
       if(value ==1)
       {
		    setaccuracyloader(0)
			setrateloader(0)
			setblastloader(1)
			setspeedloader(1)
			setweaponloader(1)
	   } 
	   else if(value ==2)
       {
		    setweaponloader(0)
		    setspeedloader(0)
			setaccuracyloader(1)
			setrateloader(1)
			setblastloader(1)
			
			
	   } 
	   else if(value ==3  || value==4 )
       {
		   setaccuracyloader(0)
		   setblastloader(0)
		   setweaponloader(1)
		   setspeedloader(1)
		   setrateloader(1)
			
	   } 
	   else if(value ==5 || value ==6 || value ==7 )
       {
		   setaccuracyloader(0)
		   setrateloader(0)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)
		   
	   } 
	   else
	   {
		   setaccuracyloader(1)
		   setrateloader(1)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)  
	   }
	   
    }

    const columns = [
        {
          key: "Sno.",
          text: "Sno.",
          cell: (row, index) => index + 1,
        },
    
        {
          key: "name",
          text: "Wallet",
          cell: (item) => {
            return `${item.wallet}`;
          },
        },  
        {
          key: "action",
          text: "Action",
          cell: (item) => {
            return (
              <>            
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  id="editbtnid"
                  onClick={() => deletewallet(item.id)}
                >
                  Delete
                </button>
                &nbsp;
              </>
            );
          },
        },
      ];
    
      const configForTable = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: "advance",
        button: {
          excel: false,
          print: false,
        },
      };

      const deletewallet = async (id) => {
        Swal.fire({
          title: "Are you sure?",
          text: "You want to remove this wallet from whitelist list!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Remove it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res = await deletewhitelistAction({ id: id });
            if (res.success) {
                getWhitelistNFTWalletListAPI();
              // toast.success(res.msg);
              Swal.fire("Deleted!", res.msg, "success");
            } else {
              Swal.fire("Failed!", res.msg, "error");
              // toast.error(res.msg);
            }
          }
        });
      };

    return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Edit License</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Edit License</h4>
                                        </div>
                                        <div className="row mt-20 mb-50 ml-15 mr-15">
                                            <form onSubmit={updateNFt}>
                                                <div className="row">                                                   
                                                    <div className="col-md-6">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Title
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="title"
                                                                    id='titleError'
                                                                    onChange={inputHandler} value={NftDetails?.title}
                                                                    placeholder="Enter title"
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.titleError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                          <div className="col-md-6">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Select City
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='city_id' className="form-control" id='cityError' onChange={inputHandler} value={NftDetails?.city_id}>
                                                                    <option value="0">Select City</option>
                                                                    {Cities.map(city => (
                                                                        <option value={city.id}>{city.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.cityError}
                                                            </span>
                                                        </div>
                                                        </div> 
                                                          <div className="col-md-6">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                License Type
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='nft_type' className="form-control" id='typeError' onChange={inputHandler} value={NftDetails?.nft_type}>
                                                                    <option value="0">Select NFT Type</option>
                                                                    {Types.map(type => (
                                                                        <option value={type.id}>{type.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.typeError}
                                                            </span>
                                                        </div>
                                                        </div> 
                                                        <div className="col-md-6">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Royalties(%)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='royalty_percentage' className="form-control" onChange={inputHandler} value={NftDetails?.royalty_percentage} type="text" placeholder="Eg. 5%, 10%, 15%" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                        </div>
														</div>  
                                                         <div className="col-md-6 dpnone">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Category
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='category_id' className="form-control" id='categoryError' onChange={inputCatHandler} value={NftDetails?.category_id}>
                                                                    <option value="0">Select Category</option>
                                                                    {Category.map(cat => (
                                                                        <option value={cat.id}>{cat.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.categoryError}
                                                            </span>
                                                        </div>
														</div>
														<div className="col-md-12">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Description
                                                            </label>
                                                            <div className="col-md-12">
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="description"
                                                                    id='descriptionError'
                                                                    onChange={inputHandler} value={NftDetails?.description}
                                                                    placeholder="Enter description"
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.descriptionError}
                                                            </span>
                                                        </div>
													  </div>
													  <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                               Damage
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Damage' className="form-control" id='DamageError' onChange={inputHandler} value={NftDetails?.Damage}>
																	<option value="0">Select Damage</option>
																	<option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div> 
                                                             <span className="validationErr">
                                                                {validatioError.DamageError}
                                                            </span>                                                      
                                                        </div>
                                                         </div>
                                                         <div className={accuracyloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                               Accuracy
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Accuracy' className="form-control" id='AccuracyError' onChange={inputHandler} value={NftDetails?.Accuracy}>
																	<option value="0">Select Accuracy</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.AccuracyError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         <div className={rateloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Rate of Fire
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Rate_of_Fire' className="form-control" id='Rate_of_FireError' onChange={inputHandler} value={NftDetails?.Rate_of_Fire}>
																	<option value="0">Select Rate of Fire</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Rate_of_FireError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={blastloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Blast Radius
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Blast_Radius' className="form-control" id='Blast_RadiusError' onChange={inputHandler} value={NftDetails?.Blast_Radius}>
																	<option value="0">Select Blast Radius</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Blast_RadiusError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={speedloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Speed Rating
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Speed_Rating' className="form-control" id='Speed_RatingError' onChange={inputHandler} value={NftDetails?.Speed_Rating}>
																	<option value="0">Select Speed Rating</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Speed_RatingError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={weaponloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Weapon reach
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Weapon_reach' className="form-control" id='Weapon_reachError' onChange={inputHandler}  value={NftDetails?.Weapon_reach}>
																	<option value="0">Select Weapon reach</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Weapon_reachError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                          <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Rarity
                                                            </label>
                                                            <div className="col-md-12">
                                                             <select name='Rarity' className="form-control" id='Rarity' onChange={inputHandler} value={NftDetails?.Rarity}>
																	<option value="">Select Rarity</option>
                                                                    <option value="Common">Common</option>
                                                                    <option value="Epic">Epic</option>
                                                                    <option value="Legendary">Legendary</option>
                                                                    <option value="Rare">Rare</option>
                                                                    <option value="Unique">Unique</option>
                                                                 </select>
                                                            </div>
                                                             <span className="validationErr">
                                                                {validatioError.RarityError}
                                                            </span>   
                                                           
                                                        </div>
                                                         </div>
                                                         <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Rarity Weightage
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='Rarity_Weightage' className="form-control" id='Rarity_Weightage' onChange={inputHandler} value={NftDetails?.Rarity_Weightage}>
                                                                    <option value="0">Select Rarity Weightage</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div> 
                                                             <span className="validationErr">
                                                                {validatioError.Rarity_WeightageError}
                                                            </span>                                                      
                                                        </div>
                                                         </div>
													  
													  
													  
														
														<div className="col-md-6">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Price(SIN)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='price' id='priceError' className="form-control" onChange={inputHandler} value={parseInt(NftDetails?.price)} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.priceError}
                                                            </span>
                                                        </div>
														</div>
														<div className="col-md-6 dpnone">
                                                        <div className="form-group mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Price(BNB)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='bnb_price' id='bnb_priceError' className="form-control" onChange={inputHandler} value={parseInt(NftDetails?.bnb_price)} type="text" placeholder="Enter Price (BNB)" onKeyPress={(event) => { if (!/^\d*[.]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.bnb_priceError}
                                                            </span>
                                                        </div>
														</div>
														<div className="col-md-6 dpnone"></div>
														<div className="col-md-6">
                                                        {/* <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Sell Type
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select value={NftDetails?.sell_type} name='sell_type' className="form-control" id='sellType' onChange={inputHandler}>
                                                                    <option value="1">Price</option>
                                                                    <option value="2">Auction</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {NftDetails.sell_type == 2 ?
                                                            <>
                                                                <div className="form-group row mb-1">
                                                                    <label className="col-form-label col-md-12">
                                                                        Start Date
                                                                    </label>
                                                                    <DatePicker onChange={handleChangeStartDate} minDate={currentDate} autoComplete="off" name="start_date" id="startDateError" className="form-control" value={NftDetails.start_date} />
                                                                    <span className="validationErr">{validatioError.startDateError}</span>
                                                                </div>

                                                                <div className="form-group row mb-1">
                                                                    <label className="col-form-label col-md-12">
                                                                        Expiry Date
                                                                    </label>
                                                                    <DatePicker onChange={handleChangeExpiryDate} minDate={currentDate} value={NftDetails.expiry_date} autoComplete="off" id="expiryDateError" name="expiry_date" className="form-control" />
                                                                    <span className="validationErr">{validatioError.expiryDateError}</span>
                                                                </div></>
                                                            : ""} */}

                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Image
                                                            </label>
                                                            <div className="col-md-12">
                                                                {image_preview ?
                                                                    <>
                                                                        <img className='nftImg' src={image_preview} /> &nbsp;
                                                                    </>
                                                                    :
                                                                    NftDetails?.image ?
                                                                        <>
                                                                            <img className='nftImg' src={`${config.ipfsUrl + NftDetails?.image}`} /> &nbsp;
                                                                        </>
                                                                        : ""
                                                                }
                                                                <br />
                                                                <span className="filename">PNG, JPG, GIF, WEBP</span>
                                                                <input onChange={imageUpload} type="file" className="inputfile form-control" name="file" />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.imageError}
                                                            </span>
                                                        </div>
														</div>
														<div className="col-md-12">
                                                        <br />
                                                        <div className="text-center pull-left">
                                                            {spinloader == '0' ?
                                                                <button className='btn btn-primary' >Submit</button>
                                                                :
                                                                <button disabled className='btn btn-primary' >Updating NFT <i className="fa fa-spinner fa-spin validat"></i></button>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </form>
                                        </div>
                                        <div className="row mt-20 mb-50 ml-15">
                                            <div className="box-header with-border">
                                            <h4 className="box-title">Whitelist Wallet list</h4>
                                            <a
                                                href="javascript:;" onClick={() => { addWalletModelAPI() }} data-toggle="modal" data-target="#addWALLET"
                                                className="btn btn-sm btn-primary add_btn"
                                            >
                                                Add
                                            </a>
                                           
                                            </div>

                                            <div className="box-body"> 
                                                <ReactDatatable
                                                    config={configForTable}
                                                    records={WhitelistNftWalletList}
                                                    columns={columns}
                                                />        
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* /.content */}
                    </div>
                </div>

                <Footer />
            </div>

             {/* ADD WALLET model */}
             <div className={isaddWALLET === 0 ? "modal fade" : "modal fade show"} id="addWALLET" style={{ display: isaddWALLET === 0 ? 'none' : 'block' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                <div className="modal-dialog" role="document">

                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel"> Add Wallet Address </h5>
                            <a type="button" className="close" data-dismiss="modal" style={{
                                fontSize: '26px'
                            }} aria-label="Close" onClick={closeWalletModel} >
                                <span aria-hidden="true">&times;</span>
                            </a>
                        </div>

                        <div className="modal-body">
                            <div className="de_tab tab_methods">
                                <div className="de_tab_content">
                                    <h5>Please enter wallet address?</h5>
                                     <input type="text" value={addwallet} name="addwallet" id="addwallet" className="form-control" placeholder="Enter wallet address" onInput={e => setAddWallet(e.target.value)}/>
                                  
                                </div>
                            </div>
                            <div className="spacer-10 mt-10" />
                            {spinloader == '0' ?
                                <input type="submit" onClick={() => { addWALLET(id) }} value="Add" id="submit" className="btn-main btn btn-primary" defaultValue="Add" />
                                :
                                <button disabled className="btn-main" id="deposit-page" >Processing &nbsp; <i className="fa fa-spinner fa-spin validat"></i></button>
                            }
                            <div className="spacer-single" />
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};
export default EditNFT;
