import React, { useEffect, useState } from 'react'
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from 'react-hot-toast';
import Web3 from 'web3';
import Cookies from 'js-cookie';
import config from '../coreFIles/config';
import Modal from 'react-modal';
import { getNftListAction, getContractWalletNFTsAction, collectedNFTsCreateAction, getCategoryAction } from '../Action/action';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const WalletNFTs = () => {
    let subtitle;
    const [NftList, setNftList] = useState({});
    const [NFTList, setNFTList] = useState([]);
    const [isLoader, setisLoader] = useState(1);
    const [connectWalletAddress, setConnectWalletAddress] = useState('');
    const [isPutonsale, setisPutonsale] = useState(0);
    const [currentDate, setcurrentDate] = useState(new Date());
    const [validatioError, setvalidatioError] = useState({});
    const [spinLoader, setSpinLoader] = useState(0);
    const [Category, setCategory] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [blockchainUpdationType, setblockchainUpdationType] = useState(0);

    const [itemDetails, setItemDetails] = useState({
        title: '',
        token_id: '',
        image: '',
        description: '',
        category_id: '0',
        royalty_percentage: '0',
        price: '0',
        sell_type: "1",
        start_date: null,
        expiry_date: null,
        contractAddress: '',
        wallet_address: '',
        tokenUri: ''
    });

    useEffect(() => {

        if (window.ethereum) {
            window.ethereum.on('accountsChanged', function (accounts) {
                setNFTList([]);
                setisLoader(1)
                setConnectWalletAddress(accounts[0]);
                if (accounts) {
                    getContractNFTsAPI(accounts[0]);
                }
            })
        }

        setTimeout(async () => {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setConnectWalletAddress(accounts[0]);
                if (accounts) {
                    getContractNFTsAPI(accounts[0]);
                }
            }
        }, 200);

        getNftList();
        getCategoryAPI();
    }, [])

    const getNftList = async () => {
        let res = await getNftListAction();
        if (res.success) {
            setNftList(res.data);
        }
    }

    const getCategoryAPI = async () => {
        let res = await getCategoryAction();
        if (res.success) {
            setCategory(res.data)
        }
    }

    const getContractNFTsAPI = async (walletAddress) => {
        let res = await getContractWalletNFTsAction({ 'wallet_address': walletAddress });
        setisLoader(0);
        if (res.success) {
            setNFTList(res.data);
        }
    }

    const columns = [
        {
            key: "Sno.",
            text: "Sno.",
            cell: (row, index) => index + 1
        },
        {
            key: "#",
            text: "Name",
            cell: (item) => {
                return (
                    <>
                        {item.name}
                    </>
                );
            }
        },
        {
            key: "#",
            text: "Token ID",
            cell: (item) => {
                return (
                    <>
                        #{item.tokenId}
                    </>
                );
            }
        },
        {
            key: "image",
            text: "Image",
            cell: (item) => {
                return (
                    <>
                        {item.tokenUri ?
                            <img height={50} width={50} src="images/no-image.png" alt="NFTs" />
                            :
                            <img height={50} width={50} src="images/no-image.png" alt="NFTs" />
                        }
                    </>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            cell: (item) => {
                return (
                    <>
                        <button onClick={() => { putOnSaleModelAPI(item) }} className='btn-sm btn-primary' data-toggle="modal" data-target="#putOnSale">Put On Sale</button>
                    </>
                );
            }
        },
    ];

    const configForTable = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }

    const putOnSaleModelAPI = async (item) => {
        setisPutonsale(1);
        setItemDetails({
            title: item.name ? item.name : '',
            image: item.tokenUri,
            // title: item.metadata?.name,
            // image: item.metadata?.image,
            description: item.metadata?.description ? item.metadata?.description : '',
            token_id: item.tokenId,
            contractAddress: item.contractAddress,
            category_id: '0',
            royalty_percentage: '0',
            price: '0',
            sell_type: "1",
            start_date: null,
            expiry_date: null,
            wallet_address: '',
        });
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    const closeModel = async () => {
        setisPutonsale(0);
    }

    const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['start_date']: startDate });
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setItemDetails({ ...itemDetails, ['expiry_date']: expiryDate });
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setItemDetails({ ...itemDetails, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    function validate() {
        let titleError = "";
        let descriptionError = "";
        let categoryError = "";
        let priceError = "";
        let startDateError = "";
        let expiryDateError = "";
        if (itemDetails.title === '') {
            titleError = "Title field is required."
        }
        if (itemDetails.description === '') {
            descriptionError = "Description field is required."
        }
        if (itemDetails.category_id == 0) {
            categoryError = "Category field is required."
        }
        if (itemDetails.price == 0) {
            priceError = "Price field is required."
        }
        if (itemDetails.sell_type == 2) {
            if (itemDetails.start_date === '' || itemDetails.start_date === null) {
                startDateError = "Start date required."
            }
            if (itemDetails.expiry_date === '' || itemDetails.expiry_date === null) {
                expiryDateError = "Expiry date required."
            }
        }
        if (titleError || descriptionError || categoryError || priceError || startDateError || expiryDateError) {
            setvalidatioError({
                titleError, descriptionError, categoryError, priceError, startDateError, expiryDateError
            })
            return false
        } else {
            return true
        }
    }

    const approveNFT = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {

            if (window.ethereum) {
                let web3 = '';
                web3 = new Web3(window.ethereum);
                const accounts = await web3.eth.getAccounts();

                let walletAdd = accounts[0];
                if (!walletAdd) {
                    toast.error('Please connect your metamask wallet.');
                    return;
                }

                let from_address = accounts[0];
                var getBalace = await web3.eth.getBalance(from_address) / (10 ** 18);
                var currentBal = parseFloat(getBalace).toFixed(6)
                if (currentBal == 0) {
                    toast.error(`Insufficient fund!!`);
                    return false;
                }

                let currentNetwork = await web3.currentProvider.chainId;
                web3.eth.defaultAccount = accounts[0];
                let chainId = config.chainId;
                if (currentNetwork !== chainId) {
                    toast.error('Please select BNB testnet smartchain!!');
                    return false;
                }

                //  Approve transaction
                const contractForApprove = await new web3.eth.Contract(config.nftContractABI, itemDetails?.contractAddress);
                let isApproved = await contractForApprove.methods.isApprovedForAll(from_address, config.mainMarketplaceContract).call();

                if (isApproved == false) {
                    let tx_builderForToken = await contractForApprove.methods.setApprovalForAll(config.mainMarketplaceContract.toString(), true);

                    setSpinLoader(1);
                    setDialogOpen(true);

                    let encodedTxForToken = tx_builderForToken.encodeABI();
                    let gasPriceForToken = await web3.eth.getGasPrice();

                    let gasLimitForToken = await web3.eth.estimateGas({
                        gasPrice: web3.utils.toHex(gasPriceForToken),
                        to: itemDetails?.contractAddress,
                        from: from_address,
                        chainId: chainId,
                        data: encodedTxForToken
                    });

                    await web3.eth.sendTransaction({
                        gasPrice: web3.utils.toHex(gasPriceForToken),
                        gas: web3.utils.toHex(gasLimitForToken),
                        to: itemDetails?.contractAddress,
                        from: from_address,
                        chainId: chainId,
                        data: encodedTxForToken
                    });
                }

                setSpinLoader(1);
                setisPutonsale(0);
                setDialogOpen(true);
                try {
                    let mintFee = 0;
                    let SalePrice;
                    let start_date = 0;
                    let expiry_date = 0;
                    let tokenId = itemDetails.token_id;

                    if (itemDetails.sell_type == 1) {                        
                        SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');  
                    }

                    else if (itemDetails.sell_type == 2) {
                       SalePrice = web3.utils.toWei(itemDetails.price.toString(), 'ether');  
                        start_date = Math.round(new Date(itemDetails.start_date).getTime() / 1000);
                        expiry_date = Math.round(new Date(itemDetails.expiry_date).getTime() / 1000);
                    }

                    let contractAddress = `${config.mainMarketplaceContract}`
                    const contract = await new web3.eth.Contract(config.mainMarketplaceContractABI, contractAddress);

                    setblockchainUpdationType(1)

                    await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        '0'
                    ).call();

                    var tx_builder = await contract.methods.putonSale(
                        tokenId.toString(),
                        SalePrice.toString(),
                        itemDetails?.contractAddress.toString(),
                        itemDetails.sell_type.toString(),
                        start_date.toString(),
                        expiry_date.toString(),
                        '0'
                    );

                    let encoded_tx = tx_builder.encodeABI();
                    let gasPrice = await web3.eth.getGasPrice();
                    gasPrice = parseInt(gasPrice) + parseInt(10000000000);

                    let gasLimit = await web3.eth.estimateGas({
                        gasPrice: web3.utils.toHex(gasPrice),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });

                    const txData = await web3.eth.sendTransaction({
                        gasPrice: web3.utils.toHex(gasPrice),
                        gas: web3.utils.toHex(gasLimit),
                        to: contractAddress,
                        from: from_address,
                        value: web3.utils.toHex(mintFee),
                        chainId: chainId,
                        data: encoded_tx
                    });

                    if (txData.transactionHash) {

                        itemDetails.wallet_address = from_address;
                        let res = await collectedNFTsCreateAction(itemDetails);
                        if (res.success) {
                            toast.success(res.msg);
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        } else {
                            toast.error(res.msg);
                        }
                    } else {
                        toast.error('Something went wrong please try again.');
                        setSpinLoader(0);
                        setisPutonsale(1);
                        setDialogOpen(false);
                        return false;
                    }

                } catch (err) {
                    console.log(err);
                    if (err.message.toString().split('insufficient funds')[1]) {
                        toast.error('Transaction reverted : ' + err.message)
                    } else {
                        if (err.toString().split('execution reverted:')[1]) {
                            toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])

                        } else {
                            if (err.toString().split('execution reverted:')[1]) {
                                toast.error('Transaction reverted : ' + (err.toString().split('execution reverted:')[1]).toString().split('{')[0])
                            } else {
                                toast.error(err.message);
                            }
                        }
                    }

                    setSpinLoader(0);
                    setisPutonsale(1);
                    setDialogOpen(false);
                    return false;
                }
            } else {
                toast.error('Please connect your metamask wallet.');
                setSpinLoader(0);
                setisPutonsale(1);
                setDialogOpen(false);
                return false;
            }
        }
    }

    return (

        <>
            <div class="wrapper">
                <Toaster />

                <Modal
                    isOpen={isDialogOpen}
                    onAfterOpen={afterOpenModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="text-center pl-3 pr-3">
                        < br />
                        {blockchainUpdationType == 1 ?
                            <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                Put on sale in progress, once process completed NFT will be display on marketplace page.
                            </h4>
                            :
                            blockchainUpdationType == 2 ?
                                <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                    Canceling your listing will unpublish this sale from Sinverse and requires a transaction.
                                </h4>
                                :
                                <h4 style={{ color: '#d71e5b', fontSize: '16px' }}>
                                    Bid accepting in progress, Please wait for a while.
                                </h4>
                        }

                        <p style={{ color: '#091f3f' }}>
                            Please do not refresh page or close tab.
                        </p>
                        <div>
                            <img src="images/loader.gif" height={50} width={50} />
                        </div>
                    </div>
                </Modal>

                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Wallet NFTs List</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-body">
                                            {isLoader == 0 ?
                                                <ReactDatatable
                                                    config={configForTable}
                                                    records={NFTList}
                                                    columns={columns}
                                                />
                                                :
                                               <p className="text-center">  <img className='loaderImg' height={50} width={50} src="images/loader.gif" /> <br /><br />
                                               Please connect your metamask wallet. </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* Put on sale model */}
                <div className={isPutonsale === 0 ? "modal fade" : "modal fade show"} id="putOnSale" style={{ display: isPutonsale === 0 ? 'none' : 'block', overflowY: 'auto' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog putonsale" role="document">

                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> Put On Sale </h5>
                                <a type="button" className="close" data-dismiss="modal" style={{
                                    fontSize: '26px'
                                }} aria-label="Close" onClick={closeModel} >
                                    <span aria-hidden="true">&times;</span>
                                </a>
                            </div>

                            <div className="modal-body">
                                <div className="de_tab tab_methods">
                                    <div className="de_tab_content">
                                        <form onSubmit={approveNFT} autoComplete="off">
                                            <div className="row">
                                                <div className="col-md-2"></div>
                                                <div className="col-md-8">
                                                    <div className="form-group row mb-1">
                                                        <label className="col-form-label col-md-12">
                                                            Title
                                                        </label>
                                                        <div className="col-md-12">
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                value={itemDetails.title} name='title'
                                                                id='titleError'
                                                                onChange={inputHandler}
                                                                placeholder="Enter title"
                                                            />
                                                        </div>
                                                        <span className="validationErr">
                                                            {validatioError.titleError}
                                                        </span>
                                                    </div>

                                                    <div className="form-group row mb-1">
                                                        <label className="col-form-label col-md-12">
                                                            Description
                                                        </label>
                                                        <div className="col-md-12">
                                                            <textarea
                                                                className="form-control"
                                                                type="text"
                                                                name="description"
                                                                id='descriptionError'
                                                                value={itemDetails.description}
                                                                onChange={inputHandler}
                                                                placeholder="Enter description"
                                                            />
                                                        </div>
                                                        <span className="validationErr">
                                                            {validatioError.descriptionError}
                                                        </span>
                                                    </div>

                                                    <div className="form-group row mb-1">
                                                        <label className="col-form-label col-md-12">
                                                            Category
                                                        </label>
                                                        <div className="col-md-12">
                                                            <select name='category_id' className="form-control" id='categoryError' onChange={inputHandler}>
                                                                <option value="0">Select Category</option>
                                                                {Category.map(cat => (
                                                                    <option value={cat.id}>{cat.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <span className="validationErr">
                                                            {validatioError.categoryError}
                                                        </span>
                                                    </div>

                                                    <div className="form-group row mb-1">
                                                        <label className="col-form-label col-md-12">
                                                            Sell Type
                                                        </label>
                                                        <div className="col-md-12">
                                                            <select name='sell_type' className="form-control" id='sellType' onChange={inputHandler}>
                                                                <option value="1">Price</option>
                                                                <option value="2">Auction</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="form-group row mb-1">
                                                        <label className="col-form-label col-md-12">
                                                            Price(SIN)
                                                        </label>
                                                        <div className="col-md-12">
                                                            <input name='price' id='priceError' className="form-control" onChange={inputHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                        </div>
                                                        <span className="validationErr">
                                                            {validatioError.priceError}
                                                        </span>
                                                    </div>

                                                    {itemDetails.sell_type == 2 ?
                                                        <>
                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">
                                                                    Start Date
                                                                </label>
                                                                <DatePicker onChange={handleChangeStartDate} minDate={currentDate} autoComplete="off" name="start_date" id="startDateError" className="form-control" value={itemDetails.start_date} />
                                                                <span className="validationErr">{validatioError.startDateError}</span>
                                                            </div>

                                                            <div className="form-group row mb-1">
                                                                <label className="col-form-label col-md-12">
                                                                    Expiry Date
                                                                </label>
                                                                <DatePicker onChange={handleChangeExpiryDate} minDate={currentDate} value={itemDetails.expiry_date} autoComplete="off" id="expiryDateError" name="expiry_date" className="form-control" />
                                                                <span className="validationErr">{validatioError.expiryDateError}</span>
                                                            </div></>
                                                        : ""}
                                                    <br />
                                                    <div className="text-center pull-left">
                                                        {spinLoader == '0' ?
                                                            <button className='btn btn-primary' >Submit</button>
                                                            :
                                                            <button disabled className='btn btn-primary' >Processing <i className="fa fa-spinner fa-spin validat"></i></button>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-2"></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Footer />
            </div>
        </>
    )

}
export default WalletNFTs;
