import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import { insertcityAction } from "../Action/action";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';


const Addcity = () => {
  const [form, setForm] = useState({ name: "",image: "",is_active: "" });
  const [validatioError, setvalidatioError] = useState({});
  const [image_preview, setimage_preview] = useState('');
  const [image_file, setimage_file] = useState('');

  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };
  
  const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['image']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }

  function validate() {
    let nameError = "";
    let imageError = "";
    let statusError = "";

    if (form.name === "") {
      nameError = "City Name is required.";
    }
    if (form.image === "") {
      imageError = "City image is required.";
    }
    if (form.is_active === "") {
      statusError = "City status is required.";
    }

    if (nameError || imageError || statusError) {
      setvalidatioError({
        nameError, imageError, statusError,
      });
      return false;
    } else {
      return true;
    }
  }
  const insertcity = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
		let res = await insertcityAction(form);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}cities`;
        }, 1200);
      } else {
        toast.error(res.msg);
      }
    }
  };

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">City</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}

            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Add City</h4>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                         <form className="form-profile" onSubmit={insertcity}>
                          <div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                              City Name
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={inputHandler}
                                placeholder="City Name"
                              />
                            </div>
                            <span className="validationErr">
                              {validatioError.nameError}
                            </span>
                          </div>
                        <div className="form-group row mb-1">
							<label className="col-form-label col-md-12">
								Status
							</label>
							<div className="col-md-12">
								<select name='is_active' className="form-control" id='is_active' onChange={inputHandler}>
									<option value="">Status</option>
									<option value="1">Active</option>
									<option value="0">In-Active</option>
								</select>
							</div>
							<span className="validationErr">
								{validatioError.statusError}
							</span>
						</div>						
                         <div className="sc-card-profile text-center">
                                <div className="card-media">
                                    {image_preview ?
                                        <img id="profileimg" src={image_preview ? image_preview : avt} alt="City image" />
                                        :
                                        !form?.image || form?.image == null || form?.image == 'null' ?
                                            <img id="profileimg" src="images/noimage.png" alt="City image" />
                                            :
                                            <img id="profileimg" src={`${config.imageUrl + form?.image}`} alt="City image" />
                                    }
                                </div>
                                <div id="upload-profile">
                                    <Link to="#" className="btn-upload">
                                        Upload Image </Link>
                                    <input onChange={PicChange} id="tf-upload-img" accept="image/png, image/jpeg" type="file" name="profile" required="" />
                                </div>
                        </div>

                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary"                              
                            >
                              Save
                            </button>
                          </div>
                          </form>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Addcity;
