import React, { useEffect, useState } from 'react'
import config from '../coreFIles/config';
import toast, { Toaster } from "react-hot-toast";
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import ReactDatatable from '@ashvin27/react-datatable';
import { getContractsListAction,getContractsTypeListAction, deleteContractAction, insertContractAction, contractStatusUpdateAction } from '../Action/action';
import Swal from "sweetalert2";
import moment from "moment";
import { CSVLink } from 'react-csv';

const Nftlist = () => {
    const [NftList, setNftList] = useState({});
    const [CSVData, setCSVData] = useState([]);
    const [isModel, setisModel] = useState(0);
    const [contracttypes, setcontracttypes] = useState([]);

    const [form, setForm] = useState({ contract: "", contract_type: "0" });
    const [validatioError, setvalidatioError] = useState({});

    const inputHandler = async (e) => {
        const { name, value } = e.target;
        setForm((old) => {
            return { ...old, [name]: value };
        });
    };

    useEffect(() => {
        getContractsList();
        getcontracttypes();
    }, [])

    function validate() {
        let contractError = "";
        let contracttypeError = "";

        if (form.contract === "") {
            contractError = "Field is required.";
        }
        
         if (form.contract_type === "0") {
            contracttypeError = "Field is required.";
        }

        if (form.contract && form.contract.length < 42) {
            contractError = "Invalid contract address.";
        }

        if (contractError || contracttypeError) {
            setvalidatioError({
                contractError,contracttypeError,
            });
            return false;
        } else {
            return true;
        }
    }

    const insertContract = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) {
        } else {
            let res = await insertContractAction(form);
            if (res.success) {
                setisModel(0);
                toast.success(res.msg);
                getContractsList();
            } else {
                toast.error(res.msg);
            }
        }
    };

    const getContractsList = async () => {
        let res = await getContractsListAction();
        if (res.success) {
            setNftList(res.data);
            if (res.data.length > 0) {
                let csvData = res.data;
                let csvAllData = [];
                for (let i = 0; i < csvData.length; i++) {
                    if (i == 0) {
                        csvAllData[i] = ['Contract'];
                    }
                    csvAllData[i + 1] = [csvData[i].contract];
                }
                setCSVData(csvAllData);
            }
        }
    }
    
     const getcontracttypes = async () => {
        let res = await getContractsTypeListAction();
        if (res.success) {
            setcontracttypes(res.data);           
        }
    }

    const columns = [
        {
            key: "#",
            text: "#",
            cell: (row, index) => index + 1
        },
        {
            key: "contract",
            text: "Contract"
        },
         {
            key: "title",
            text: "Contract Type"
        },
        {
            key: "status",
            text: "Status",
            cell: (item) => {
                return (
                    <>
                        <div class="btn-group mb-5">
                            {item.status == 1 ?
                                <>
                                    <span style={{ color: 'green' }}>Active</span>
                                </>
                                :
                                <>
                                    <span style={{ color: 'red' }}>Inactive</span>
                                </>
                            }
                        </div>
                    </>
                );
            }
        },
        {
            key: "action",
            text: "Action",
            cell: (item) => {
                return (
                    <>
                        <div class="btn-group mb-5">
                            <button onClick={() => deleteContract(item.id)} className='btn-sm btn-danger'> Delete </button>
                        </div> &nbsp;

                        {item.status === 0 ?
                            <button type="button" className='btn-sm btn-success' onClick={() => contractStatusUpdate(item.id, 1)}><i className='fa fa-unlock'></i> Active</button>
                            : item.status === 1 ?
                                <button type="button" className='btn-sm btn-danger' onClick={() => contractStatusUpdate(item.id, 0)}><i className='fa fa-ban'></i> In Active</button>
                                :
                                ''
                        }
                    </>
                );
            }
        },

    ];

    const configForTable = {
        page_size: 10,
        length_menu: [10, 20, 50],
        show_filter: true,
        show_pagination: true,
        pagination: 'advance',
        button: {
            excel: false,
            print: false

        }
    }

    const deleteContract = async (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You want to Delete this contract!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, Deleted it!",
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await deleteContractAction({ id: id });
                if (res.success) {
                    getContractsList();
                    Swal.fire(res.msg, "success");
                } else {
                    Swal.fire(res.msg, "error");
                }
            }
        });
    };

    const contractStatusUpdate = async (id, is_active) => {
        Swal.fire({
            title: 'Are you sure?',
            text: is_active == 0 ? "You want to inactive this contract!" : "You want to active this contract!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Submit'
        }).then(async (result) => {
            if (result.isConfirmed) {
                let res = await await contractStatusUpdateAction({ 'id': id, 'status': is_active });
                if (res.success) {
                    Swal.fire(
                        is_active == 0 ? 'Inactivated successfully!!' : 'Activated successfully!!',
                    )
                    getContractsList();
                } else {
                    Swal.fire(
                        'Failed!',
                        res.msg,
                    )
                }
            }
        })
    }

    const closeModel = async () => {
        setisModel(0);
    }

    const openModel = async () => {
        setisModel(1);
    }

    return (

        <>
            <div class="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Contracts List</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-body">
                                            <button className='btn-sm btn-primary pull-right' onClick={openModel} >Add+</button>

                                            {CSVData.length > 0 ?
                                                <CSVLink data={CSVData} > <button className="btn-sm btn-primary"> Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i></button> <br /><br /></CSVLink>
                                                : ""
                                            }
                                            <ReactDatatable
                                                config={configForTable}
                                                records={NftList}
                                                columns={columns}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <div className={isModel == 0 ? "modal fade" : "modal fade show"} id="productShareSheet" style={{ display: isModel == 0 ? 'none' : 'block', paddingTop: '50px' }} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Add Contract</h5>
                                <button type="button" className="close bidsclose" data-dismiss="modal" style={{
                                    fontSize: '26px'
                                }} aria-label="Close " onClick={() => closeModel()} >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row mt-20 mb-50">
                                    <div className="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8">
                                            <div className="form-group row mb-1">
                                                <label className="col-form-label col-md-12">
                                                    Enter Contract Address
                                                </label>
                                                <div className="col-md-12">
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="contract"
                                                        onChange={inputHandler}
                                                        placeholder="0xD58ds....."
                                                    />
                                                </div>
                                                <span className="validationErr">
                                                    {validatioError.contractError}
                                                </span>
                                            </div>
                                            <div className="form-group row mb-1">
												<label className="col-form-label col-md-12">
													License Type
												</label>
												<div className="col-md-12">
													<select name='contract_type' className="form-control" id='contracttypeError' onChange={inputHandler}>
														<option value="0">Select Contract Type</option>
														{contracttypes.map(type => (
															<option value={type.id}>{type.title}</option>
														))}
													</select>
												</div>
												<span className="validationErr">
													{validatioError.contracttypeError}
												</span>
											</div>

                                            <div>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={insertContract}
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                        <div className="col-md-2"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </>


    )

}
export default Nftlist;
