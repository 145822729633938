import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
  postRequestFormData,
} from "../coreFIles/helper";

export const LoginAction = (data) => {
  return postRequest("adminLogin", data).then((res) => {
    return res.data;
  });
};

export const getDashboardStatisticsAction = (data) => {
  return postRequest("getDashboardStatistics", data).then((res) => {
    return res.data;
  });
};

export const getUsersListAction = (data) => {
  return getRequest("getAdminUsersList", data).then((res) => {
    return res.data;
  });
};
export const getNftListAction = (data) => {
  return getRequest("getNftList", data).then((res) => {
    return res.data;
  });
};
export const getUserLicenseListAction = (data) => {
  return getRequest("getUserLicenseList", data).then((res) => {
    return res.data;
  });
};



export const loginAsUserAction = (data) => {
  return postRequest("loginAsUser", data).then((res) => {
    return res.data;
  });
};

export const getTransactionHistoryAction = (data) => {
  return postRequest("getTransactionHistory", data).then((res) => {
    return res.data;
  });
};

export const changePasswordAction = (data) => {
  return postRequest("changePassword", data).then((res) => {
    return res.data;
  });
};

export const UserBlockAction = (data) => {
  return postRequest("usersblockunblock", data).then((res) => {
    return res.data;
  });
};
export const UserBlockUnBlockAction = (data) => {
  return postRequest("usersblockunblock", data).then((res) => {
    return res.data;
  });
};
export const UserUnBlockAction = (data) => {
  return postRequest("userUnblock", data).then((res) => {
    return res.data;
  });
};
export const insertcategoryAction = (data) => {
  return postRequest("insertcategorylist", data).then((res) => {
    return res.data;
  });
};

export const getcategoryAction = (data) => {
  return postRequest("getcategoryname", data).then((res) => {
    return res.data;
  });
};

export const getcategorylistAction = (data) => {
  return getRequest("getcategorylist", data).then((res) => {
    return res.data;
  });
};

export const getcitieslistAction = (data) => {
  return getRequest("getcitylist", data).then((res) => {
    return res.data;
  });
};

export const getAllcitieslistAction = (data) => {
  return getRequest("getallcitylist", data).then((res) => {
    return res.data;
  });
};
export const getsliderlistAction = (data) => {
  return getRequest("getsliderlist", data).then((res) => {
    return res.data;
  });
};
export const insertsliderAction = (data) => {
  return postRequestFormData("insertslider", data).then((res) => {
    return res.data;
  });
};

export const insertcityAction = (data) => {
  return postRequestFormData("insertcity", data).then((res) => {
    return res.data;
  });
};

export const getnfttypelistAction = (data) => {
  return getRequest("getnfttypelist", data).then((res) => {
    return res.data;
  });
};

export const deletecategoryAction = (data) => {
  return postRequest("deletecategory", data).then((res) => {
    return res.data;
  });
};

export const deletecityAction = (data) => {
  return postRequest("deletecity", data).then((res) => {
    return res.data;
  });
};

export const deletesliderAction = (data) => {
  return postRequest("deleteslider", data).then((res) => {
    return res.data;
  });
};

export const getcategoryidAction = (data) => {
  return postRequest("getcategoryidlist", data).then((res) => {
    return res.data;
  });
};
export const updatecategoryAction = (data) => {
  return postRequest("updatecategorylist", data).then((res) => {
    return res.data;
  });
};


export const getslideridAction = (data) => {
  return postRequest("getslideridlist", data).then((res) => {
    return res.data;
  });
};

export const getcityidAction = (data) => {
  return postRequest("getcityidlist", data).then((res) => {
    return res.data;
  });
};
export const updatecityAction = (data) => {
  return postRequestFormData("updatecity", data).then((res) => {
    return res.data;
  });
};

export const updatesliderAction = (data) => {
  return postRequestFormData("updateslider", data).then((res) => {
    return res.data;
  });
};

export const getTransactionListAction = (data) => {
  return getRequest("getTransactionList", data).then((res) => {
    return res.data;
  });
};
export const getAdminProfiles = (data) => {
  return getRequest('getAdminProfiles', data).then(res => { return res.data })
}
export const updateAdminProfilesAction = (data) => {
  return postRequestFormData("updateAdminProfiles", data).then((res) => {
    return res.data;
  });
};

export const createNftAction = (data) => {
  return postRequest('createUserNFT', data).then(res => { return res.data })
}

export const createMetadataAction = (data) => {
  return postRequest('createMetadata', data).then(res => { return res.data })
}

export const updateMetadataAction = (data) => {
  return postRequest('updateMetadata', data).then(res => { return res.data })
}

export const getNftDetailsAction = (data) => {
  return postRequest('getNftDetailsById', data).then(res => {return res.data})
}

export const updateNftAction = (data) => {
  return postRequest('updateNft', data).then(res => {return res.data})
}

export const cancelOrderAction = (data) => {
  return postRequest('cancelOrderAction', data).then(res => { return res.data })
}

export const putOnSaleAction = (data) => {
  return postRequest('putOnSaleAction', data).then(res => { return res.data })
}

export const getAdminNftListAction = (data) => {
  return getRequest("getAdminNftList", data).then((res) => {
    return res.data;
  });
};
export const getAdminlicensesListAction = (data) => {
  return getRequest("getAdminlicensesList", data).then((res) => {
    return res.data;
  });
};
export const getAdminSafeNftsListAction = (data) => {
  return getRequest("getAdminSafeNftsList", data).then((res) => {
    return res.data;
  });
};
export const copyAdminNftAction = (data) => {  
   return postRequest('copyAdminNft', data).then(res => { return res.data })
};
export const copyAdminLicenseAction = (data) => {  
   return postRequest('copyAdminLicense', data).then(res => { return res.data })
};
export const copyAdminSafeNftsAction = (data) => {  
   return postRequest('copyAdminSafeNfts', data).then(res => { return res.data })
};



export const deleteNFTAction = (data) => {
  return postRequest("nftdeletelist", data).then((res) => {
    return res.data;
  });
};


export const gettermsandconditionAction=(data)=>{
  return getRequest('getTermsAndConditions',data).then(res=>{return res.data})
}
export const updatetermsandconditionAction=(data)=>{
  return postRequest('updateTermandCondition',data).then(res=>{return res.data})
}
export const getFaqAction=(data)=>{
  return getRequest('getFaq',data).then(res=>{return res.data})
}
export const getfaqidAction = (data) => {
  return postRequest("getfaqidlist", data).then((res) => {
    return res.data;
  });
};
export const insertfaqAction = (data) => {
  return postRequest("insertfaqlist", data).then((res) => {
    return res.data;
  });
};
export const deletefaqAction = (data) => {
  return postRequest("faqdeletelist", data).then((res) => {
    return res.data;
  });
};
export const updatefaqAction = (data) => {
  return postRequest("updatefaqlist", data).then((res) => {
    return res.data;
  });
};
export const getPrivacyPolicyAction=(data)=>{
  return getRequest('getPrivacyPolicy',data).then(res=>{return res.data})
}
export const updatePrivacyPolicyAction=(data)=>{
  return postRequest('updatePrivacyPolicy',data).then(res=>{return res.data})
}

export const getSubscriberListAction = (data) => {
  return getRequest("getAdminSubscribeList", data).then((res) => {
    return res.data;
  });
};

export const getcontactusListAction = (data) => {
  return getRequest("getAdminContactUsList", data).then((res) => {
    return res.data;
  });
};

export const updatesociallinksAction = (data) => {
  return postRequest("updatesociallinks", data).then((res) => {
    return res.data;
  });
};

export const updatesettingsListsAction = (data) => {
  return postRequest("updatesettingsLists", data).then((res) => {
    return res.data;
  });
};

export const getSocialLinksAction = (data) => {
  return getRequest('getsociallinks', data).then(res => { return res.data })
}

export const getsettingsListAction = (data) => {
  return getRequest('getsettingsList', data).then(res => { return res.data })
}
// Whitelist
export const deletewhitelistAction = (data) => {
  return postRequest("deletewhitelist", data).then((res) => {
    return res.data;
  });
};
export const getwhitelistlistAction = (data) => {
  return getRequest("getwhitelist", data).then((res) => {
    return res.data;
  });
};
export const insertwalletAction = (data) => {
  return postRequest("insertwallet", data).then((res) => {
    return res.data;
  });
};

export const getWhitelistNFTWalletListAction = (data) => {
  return postRequest('getWhitelistNFTWalletList', data).then(res => {return res.data});
};

export const getTokentransactionListAction = (data) => {
  return getRequest("getTokentransactionList", data).then((res) => {
    return res.data;
  });
};


export const getContractsListAction = (data) => {
  return getRequest('getContractsList', data).then(res => { return res.data })
}


export const getContractsTypeListAction = (data) => {
  return getRequest('getContractsTypeList', data).then(res => { return res.data })
}



export const deleteContractAction = (data) => {
  return postRequest('deleteContract', data).then(res => { return res.data })
}

export const insertContractAction = (data) => {
  return postRequest('insertContract', data).then(res => { return res.data })
}

export const contractStatusUpdateAction = (data) => {
  return postRequest('contractStatusUpdate', data).then(res => { return res.data })
}


export const getContractNFTsAction = (data) => {
  return postRequest('getNFTfromblockchain', data).then(res => { return res.data })
}

export const getContractWalletNFTsAction = (data) => {
  return postRequest('getContractWalletNFTs', data).then(res => { return res.data })
}

export const collectedNFTsCreateAction = (data) => {
  return postRequest('collectedNFTsCreate', data).then(res => { return res.data })
}

export const getCategoryAction = (data) => {
  return getRequest('getCategory', data).then(res => { return res.data })
}
