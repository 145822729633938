import "./App.css";
import React, { components } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import config from './config/config.js'
import config from "./coreFIles/config";
import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Users from "./component/users";
import Changepassword from "./component/changepassword";
import Category from "./component/category";
import Addcategory from "./component/addcategory";
import Categoryupdate from "./component/updatecategory";
import Cities from "./component/cities";
import Addcity from "./component/addcity";
import Editcity from "./component/editcity";
import Slider from "./component/sliders";
import Addslider from "./component/addslider";
import Editslider from "./component/editslider";
import Transactionlist from "./component/transactionlist";
import Nftlist from "./component/nftlist";
import Licenselist from "./component/licenselist";
import Profile from "./component/profile";
import CreateNFT from "./component/createNFT";
import CreateLicense from "./component/createLicense";
import Createsafenft from "./component/createSafeNFT";
import EditNFT from "./component/editNFT";
import EditLicense from "./component/editLicense";
import Editsafenft from "./component/editSafenft";
import Adminnftslist from "./component/adminnftslist";
import Adminlicenseslist from "./component/adminlicenseslist";
import Adminsafenftlist from "./component/adminsafenfts";
import TermsAndCondition from "./component/TermsAndCondition";
import PrivacyPolicy from "./component/PrivacyPolicy";
import Faq from "./component/faq";
import Addfaq from "./component/addfaq";
import Contactus from "./component/contactus";
import Updatefaq from "./component/Updatefaq";
import Sociallink from './component/Sociallink'
import Settings from './component/Settings'
import Subscribers from "./component/subscribers";
import Whitelist from "./component/whitelist";
import Addwallet from "./component/addwallet";
import Tokentransactionlist from "./component/tokentransactionlist";
import ContractsList from "./component/contractsList";
import CollectedNFTs from "./component/collectedNFTs";
import WalletNFTs from "./component/walletNFTs";




function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}users`} element={<Users />} />
		  <Route path={`${config.baseUrl}whitelist`} element={<Whitelist />} />
		  <Route path={`${config.baseUrl}Addwallet`} element={<Addwallet />} />

          <Route path={`${config.baseUrl}Category`} element={<Category />} />
          
          <Route
            path={`${config.baseUrl}Addcategory`}
            element={<Addcategory />}
          />
          <Route
            path={`${config.baseUrl}Categoryupdate/:id`}
            element={<Categoryupdate />}
          />
          
           <Route path={`${config.baseUrl}cities`} element={<Cities />} />
           <Route
            path={`${config.baseUrl}Addcity`}
            element={<Addcity />}
          />
          <Route
            path={`${config.baseUrl}Editcity/:id`}
            element={<Editcity />}
          />
           
          <Route path={`${config.baseUrl}slider`} element={<Slider />} />
           <Route
            path={`${config.baseUrl}Addslider`}
            element={<Addslider />}
          />
          <Route
            path={`${config.baseUrl}Editslider/:id`}
            element={<Editslider />}
          />

          <Route
            path={`${config.baseUrl}profile`}
            element={<Profile />}
          />

          <Route
            path={`${config.baseUrl}changepassword`}
            element={<Changepassword />}
          />
          
           <Route
            path={`${config.baseUrl}contractsList`}
            element={<ContractsList />}
          />

          <Route
            path={`${config.baseUrl}nftlist`}
            element={<Nftlist />}
          />
          <Route
            path={`${config.baseUrl}licenselist`}
            element={<Licenselist />}
          />
          
           <Route
            path={`${config.baseUrl}adminsafenftlist`}
            element={<Adminsafenftlist />}
          />
          

          <Route
            path={`${config.baseUrl}createNFT`}
            element={<CreateNFT />}
          />

          <Route
            path={`${config.baseUrl}editNFT/:id`}
            element={<EditNFT />}
          />
          
           <Route
            path={`${config.baseUrl}createLicense`}
            element={<CreateLicense />}
          />

          <Route
            path={`${config.baseUrl}editLicense/:id`}
            element={<EditLicense />}
          />
           <Route
            path={`${config.baseUrl}createsafenft`}
            element={<Createsafenft />}
          />
           
          <Route
            path={`${config.baseUrl}editsafenft/:id`}
            element={<Editsafenft />}
          />

          <Route
            path={`${config.baseUrl}transactionlist`}
            element={<Transactionlist />}
          />
          
           <Route
            path={`${config.baseUrl}tokentransactionlist`}
            element={<Tokentransactionlist />}
          />

          <Route
            path={`${config.baseUrl}adminnftslist`}
            element={<Adminnftslist />}
          />
          
           <Route
            path={`${config.baseUrl}adminlicenseslist`}
            element={<Adminlicenseslist />}
          />
          

          <Route
            path={`${config.baseUrl}TermsAndCondition`}
            element={<TermsAndCondition />}
          />

          <Route
            path={`${config.baseUrl}PrivacyPolicy`}
            element={<PrivacyPolicy />}
          />
          <Route
            path={`${config.baseUrl}Faq`}
            element={<Faq />}
          />
          <Route
            path={`${config.baseUrl}addfaq`}
            element={<Addfaq />}
          />
          <Route
            path={`${config.baseUrl}subscribers`}
            element={<Subscribers />}
          />
          <Route
            path={`${config.baseUrl}Contactus`}
            element={<Contactus />}
          />
          <Route
            path={`${config.baseUrl}Settings`}
            element={<Settings />}
          />
          <Route
            path={`${config.baseUrl}Sociallink`}
            element={<Sociallink />}
          />
          <Route
            path={`${config.baseUrl}Updatefaq/:id`}
            element={<Updatefaq />}
          />
          
           <Route
            path={`${config.baseUrl}collectedNFTs`}
            element={<CollectedNFTs />}
          />
          <Route
            path={`${config.baseUrl}walletNFTs`}
            element={<WalletNFTs />}
          />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
