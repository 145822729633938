import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { updatesliderAction, getslideridAction } from "../Action/action";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import avt from '../assets/avata_profile.jpg'
import { Link } from 'react-router-dom';

const Editcity = () => {
  const [getSliderlist, setSliderList] = useState({});
  const [form, setForm] = useState({ name: "",image: "",is_active: ""  });
  const [validatioError, setvalidatioError] = useState({});
  const [image_preview, setimage_preview] = useState('');
  const [image_file, setimage_file] = useState('');
  useEffect(() => {
    getSiderid();
  }, []);

  const getSiderid = async () => {
    const id = window.location.href.split("/").pop();
    let res = await getslideridAction({ id: id  });
    if (res.success) {
      setSliderList(res.data);
      let data = res.data[0];
      setForm((old) => {
        return { ...old, id: id, title: data.title,description: data.description,link: data.link,image: data.image,is_active:data.is_active };
      });
    }
  };
  const inputHandler = async (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };
function validate() {
    let titleError = "";
    let imageError = "";
    let statusError = "";

    if (form.title === "") {
      titleError = "Slider title is required.";
    } 
    if (image_file == '' && form.image == "") {
            imageError = "Image field is required."
        }   
    if (form.is_active === "") {
      statusError = "Slider status is required.";
    }

    if (titleError || imageError || statusError) {
      setvalidatioError({
        titleError, imageError, statusError,
      });
      return false;
    } else {
      return true;
    }
  }
 const PicChange = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];
        let imageType = e.target.files[0].type;
        if (imageType == 'image/jpeg' || imageType == 'image/jpg' || imageType == 'image/png') {
            setimage_file(image_as_files);
            setimage_preview(image_as_base64);
            setForm((old) => {
                return { ...old, ['image']: image_as_files }
            })
        } else {
            toast.error('File type wrong please select JPG, JPEG or PNG.');
        }
    }
  const updateslider = async (e) => {
    e.preventDefault();    
	if (!image_file) {
		form.old_image = form.image;
	}
    let res = await updatesliderAction(form);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}slider`;
      }, 1200);
    } else {
      toast.error(res.msg);
    }
  };

  return (
    <>
      <div class="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <div className="content-header">
              <div className="d-flex align-items-center">
                <div className="me-auto">
                  <h3 className="page-title mb-5 pb-2">Edit Slider</h3>
                </div>
              </div>
              <hr />
            </div>
            {/* Content Header (Page header) */}
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Edit Slider</h4>
                    </div>
                    <div className="row mt-20 mb-50">
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div className="col-md-8">
                          <div class="form-group row mb-1">
                            <label class="col-form-label col-md-12">
                              Title
                            </label>
                            <div class="col-md-12">
                             <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={form.title}
                                onChange={inputHandler}
                                placeholder="Slider title"
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
							<label className="col-form-label col-md-12">
								Description
							</label>
							<div className="col-md-12">
								<textarea
									className="form-control"
									type="text"
									name="description"
									id='descriptionError'
									onChange={inputHandler} value={form.description}
									placeholder="Enter description"
								/>
							</div>
							<span className="validationErr">
								{validatioError.descriptionError}
							</span>
						</div>	
						<div className="form-group row mb-1">
                            <label className="col-form-label col-md-12">
                              Link
                            </label>
                            <div className="col-md-12">
                              <input
                                className="form-control"
                                type="text"
                                name="link"
                                value={form.link}
                                onChange={inputHandler}
                                placeholder="Slider link"
                              />
                            </div>                           
                          </div>
                           <div className="form-group row mb-1">
							<label className="col-form-label col-md-12">
								Status
							</label>
							<div className="col-md-12">
								<select name='is_active' className="form-control" id='is_active' onChange={inputHandler} value={form.is_active}>
									<option value="">Status</option>
									<option value="1">Active</option>
									<option value="0">In-Active</option>
								</select>
							</div>
							<span className="validationErr">
								{validatioError.statusError}
							</span>
						</div>						
                         <div className="sc-card-profile text-center">
                                <div className="card-media">
                                    {image_preview ?
                                        <img id="profileimg" src={image_preview ? image_preview : avt} alt="City image" />
                                        :
                                        !form?.image || form?.image == null || form?.image == 'null' ?
                                            <img id="profileimg" src="images/noimage.png" alt="City image" />
                                            :
                                            <img id="profileimg" src={`${config.imageUrl + form?.image}`} alt="City image 1" />
                                    }
                                </div>
                                <div id="upload-profile">
                                    <Link to="#" className="btn-upload">
                                        Upload Image </Link>
                                    <input onChange={PicChange} id="tf-upload-img" accept="image/png, image/jpeg" type="file" name="profile" required="" />
                                </div>
                        </div>

                          <br />
                          <br />
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={updateslider}
                            >
                              Save Change
                            </button>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default Editcity;
