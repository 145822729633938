import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import axios from 'axios';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getcategorylistAction, createNftAction, createMetadataAction, getnfttypelistAction, getcitieslistAction  } from "../Action/action";

const Addcategory = () => {
    const [validatioError, setvalidatioError] = useState({});
    const [Category, setCategory] = useState([]);
    const [Types, setTypes] = useState([]);
    const [Cities, setCities] = useState([]);    
    const [image_preview, setimage_preview] = useState('');
    const [image_file, setimage_file] = useState('');
    const [FileType, setFileType] = useState('');
    const [spinloader, setspinloader] = useState(0);
    const [currentDate, setcurrentDate] = useState(new Date());
    const [accuracyloader, setaccuracyloader] = useState(1);
    const [rateloader, setrateloader] = useState(1);
    const [blastloader, setblastloader] = useState(1);
    const [speedloader, setspeedloader] = useState(1);
    const [weaponloader, setweaponloader] = useState(1);   
    const [ownerAddress, setwalletAddress] = useState(false);

    const [nftdata, setnftdata] = useState({
        title: '',
        description: '',
        category_id: '0',
        royalty_percentage: '0',
        price: '0',
        bnb_price: '0',
        sell_type: "1",
        start_date: null,
        expiry_date: null,
        Damage: "0",
        Accuracy: "0",
        Rate_of_Fire: "0",
        Rarity: "",
        Rarity_Weightage: "0",
        Blast_Radius: "0",
        Speed_Rating: "0",
        Weapon_reach: "0",
        contractAddress: config.nftContract,
        owner_address: config.adminAddress,
        nft_type: "0",
        city_id:"0",
    });
    useEffect(() => {
        getCategoryAPI()
        getNFTtypeAPI()
        getCitiesAPI()
          setInterval(async () => {
            if (window.ethereum) {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setwalletAddress(accounts[0])
            }
        }, 1000);
    }, [])

    const getCategoryAPI = async () => {
        let res = await getcategorylistAction();
        if (res.success) {
            setCategory(res.data)
        }
    }
    
     const getNFTtypeAPI = async () => {
        let res = await getnfttypelistAction();
        if (res.success) {
            setTypes(res.data)
        }
    }
    
    const getCitiesAPI = async () => {
        let res = await getcitieslistAction();
        if (res.success) {
            setCities(res.data)
        }
    }
    
    


    const inputHandler = (e) => {
        const { name, value, id } = e.target
        setnftdata({ ...nftdata, [name]: value })

        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
    }

    const imageUpload = async (e) => {
        e.preventDefault()
        let image_as_base64 = URL.createObjectURL(e.target.files[0])
        let image_as_files = e.target.files[0];

        let file_type = '';
        if (image_as_files.type.indexOf('image') === 0) {
            file_type = 'image';
        } else {
            file_type = 'video';
        }

        setimage_file(image_as_files);
        setimage_preview(image_as_base64);
        setFileType(file_type);
        setvalidatioError((old) => {
            return { ...old, ['imageError']: '' }
        })
    }

    function validate() {
        let titleError 			= "";
       
        let descriptionError 	= "";        
       
        let priceError 			= "";
       
        let bnb_priceError 		= "";        
       
        let imageError 			= "";      
       
        let typeError			= "";	
        
        let cityError			= "";
				
        
        if (nftdata.title === '') {
            titleError = "Title field is required."
        }
        if (nftdata.description === '') {
            descriptionError = "Description field is required."
        }
        
        if (nftdata.price == 0) {
            priceError = "Price field is required."
        }
         if (nftdata.bnb_price == '') {
            bnb_priceError = "Price field is required."
        }        
        
        if (nftdata.nft_type == '0') {
            typeError = "Field is required."
        }
        
        if (nftdata.city_id == '0') {
            cityError = "Field is required."
        }
        
                
        if (image_file == '') {
            imageError = "Image field is required."
        }
        
        
        if (titleError || descriptionError || priceError || bnb_priceError || imageError || typeError || cityError) {
            setvalidatioError({
                titleError, descriptionError, priceError, bnb_priceError, imageError, typeError, cityError
            })
            return false
        } else {
            return true
        }
    }
    const createNFt = async (e) => {
        e.preventDefault()
        const isValid = validate();
        if (!isValid) {

        }
        else {
            setspinloader(1);
            let fileHash = await imageUploadOnPinata();
            if (fileHash) {
                let tokenId = await metaDataUpload(fileHash);
                if (tokenId) {
                    nftdata.ipfsHash = fileHash;
                    nftdata.token_id = tokenId;
                    nftdata.file_type = FileType;
                    console.log('nftdata', nftdata);
                    let res = await createNftAction(nftdata);
                    if (res.success) {
                        toast.success(res.msg);
                        setTimeout(() => {
                            window.location.href = `${config.baseUrl}adminlicenseslist`;
                        }, 2000);
                    } else {
                        toast.error(res.msg);
                    }
                } else {
                    setspinloader(0);
                    toast.error('Something went wrong for creating metadata.');
                }
            } else {
                setspinloader(0);
                toast.error('Something went wrong for uploading image on pinata.');
            }
        }
    }

    const imageUploadOnPinata = async () => {
        let formData = new FormData();
        formData.append('file', image_file);
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let resIPF = await axios.post(url,
            formData,
            {
                headers: {
                    'Content-Type': `multipart/form-data; boundary= ${formData._boundary}`,
                     'pinata_api_key': '7ca3b2043a2bf4cdeed0',
                    'pinata_secret_api_key': 'e058c8d5654ca0dc7adc1bba93aae1986d961882c9a8eda11ded091b28a6b5e0'
                }
            }
        );
        if (resIPF.data.IpfsHash) {
            let ipfsImg = resIPF.data.IpfsHash;
            return ipfsImg;
        } else {
            toast.error('Something went wrong uploading image on IPFS.');
            setspinloader(0);
            return false;
        }
    }

    const metaDataUpload = async (fileHash) => {
       let reqData = {
            "name": nftdata.title,
            "description": nftdata.description,
            "image": `https://ipfs.io/ipfs/${fileHash}`
        }

        let resIPF = await createMetadataAction(reqData);
        if (resIPF.tokenId) {
            let tokenId = resIPF.tokenId;
            return tokenId;
        } else {
            toast.error('Something went wrong creating metadata.');
            setspinloader(0);
            return false;
        }
    }

    function formatDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }


	const inputCatHandler = e => {
        const { name, value, id } = e.target
        setnftdata({ ...nftdata, [name]: value })
        if (value != '') {
            setvalidatioError((old) => {
                return { ...old, [id]: '' }
            })
        }
       if(value ==1)
       {
		    setaccuracyloader(0)
			setrateloader(0)
			setblastloader(1)
			setspeedloader(1)
			setweaponloader(1)
	   } 
	   else if(value ==2)
       {
		    setweaponloader(0)
		    setspeedloader(0)
			setaccuracyloader(1)
			setrateloader(1)
			setblastloader(1)
			
			
	   } 
	   else if(value ==3  || value==4 )
       {
		   setaccuracyloader(0)
		   setblastloader(0)
		   setweaponloader(1)
		   setspeedloader(1)
		   setrateloader(1)
			
	   } 
	   else if(value ==5 || value ==6 || value ==7 )
       {
		   setaccuracyloader(0)
		   setrateloader(0)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)
		   
	   } 
	   else
	   {
		   setaccuracyloader(1)
		   setrateloader(1)
		   setblastloader(1)
		   setweaponloader(1)
		   setspeedloader(1)  
	   }
	   
    }
    const handleChangeStartDate = e => {
        let startDate = formatDate(e);
        setnftdata({ ...nftdata, ['start_date']: startDate });
        setvalidatioError({ ...validatioError, ['startDateError']: '' });
    }

    const handleChangeExpiryDate = e => {
        let expiryDate = formatDate(e);
        setnftdata({ ...nftdata, ['expiry_date']: expiryDate });
        setvalidatioError({ ...validatioError, ['expiryDateError']: '' });
    }

    return (
        <>
            <div className="wrapper">
                <Header />
                <Toaster />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        <div className="content-header">
                            <div className="d-flex align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Create License</h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        {/* Content Header (Page header) */}

                        {/* Main content */}
                        <section className="content">
                            <div className="row">
                                <div className="col-lg-12 col-12">
                                    <div className="box">
                                        <div className="box-header with-border">
                                            <h4 className="box-title">Create License</h4>
                                        </div>
                                        <div className="row mt-20 mb-50 ml-15 mr-15">
                                            <form onSubmit={createNFt}>
                                             <div className="col-md-12">
                                                <div className="row">                                               
                                                    <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Title
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="title"
                                                                    id='titleError'
                                                                    onChange={inputHandler}
                                                                    placeholder="Enter title"
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.titleError}
                                                            </span>
                                                        </div>
                                                        </div> 
                                                         <div className="col-md-6">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Select City
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='city_id' className="form-control" id='cityError' onChange={inputHandler}>
                                                                    <option value="0">Select City</option>
                                                                    {Cities.map(city => (
                                                                        <option value={city.id}>{city.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.cityError}
                                                            </span>
                                                        </div>
                                                        </div>                                                        
                                                         <div className="col-md-6">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                License Type
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='nft_type' className="form-control" id='typeError' onChange={inputHandler}>
                                                                    <option value="0">Select NFT Type</option>
                                                                    {Types.map(type => (
                                                                        <option value={type.id}>{type.title}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.typeError}
                                                            </span>
                                                        </div>
                                                        </div>  
                                                        <div className="col-md-6">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Royalties(%)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='royalty_percentage' className="form-control" onChange={inputHandler} type="text" placeholder="Eg. 5%, 10%, 15%" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                        </div>
                                                         </div>                                                   
                                                         <div className="col-md-6 dpnone">
                                                         <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Category
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='category_id' className="form-control" id='categoryError' onChange={inputCatHandler}>
                                                                    <option value="0">Select Category</option>
                                                                    {Category.map(cat => (
                                                                        <option value={cat.id}>{cat.name}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.categoryError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Description
                                                            </label>
                                                            <div className="col-md-12">
                                                                <textarea
                                                                    className="form-control"
                                                                    type="text"
                                                                    name="description"
                                                                    id='descriptionError'
                                                                    onChange={inputHandler}
                                                                    placeholder="Enter description"
                                                                />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.descriptionError}
                                                            </span>
                                                        </div>
                                                         </div>                                                       
                                                         <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                               Damage
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Damage' className="form-control" id='DamageError' onChange={inputHandler}>
																	<option value="0">Select Damage</option>
																	<option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div> 
                                                             <span className="validationErr">
                                                                {validatioError.DamageError}
                                                            </span>                                                   
                                                        </div>
                                                         </div>
                                                         <div className={accuracyloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                               Accuracy
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Accuracy' className="form-control" id='AccuracyError' onChange={inputHandler}>
																	<option value="0">Select Accuracy</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.AccuracyError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         <div className={rateloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Rate of Fire
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Rate_of_Fire' className="form-control" id='Rate_of_FireError' onChange={inputHandler}>
																	<option value="0">Select Rate of Fire</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Rate_of_FireError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={blastloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Blast Radius
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Blast_Radius' className="form-control" id='Blast_RadiusError' onChange={inputHandler}>
																	<option value="0">Select Blast Radius</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Blast_RadiusError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={speedloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Speed Rating
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Speed_Rating' className="form-control" id='Speed_RatingError' onChange={inputHandler}>
																	<option value="0">Select Speed Rating</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Speed_RatingError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className={weaponloader == 1 ? 'col-md-6 dpnone' : 'col-md-6'}>
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                              Weapon reach
                                                            </label>
                                                            <div className="col-md-12">
                                                               <select name='Weapon_reach' className="form-control" id='Weapon_reachError' onChange={inputHandler}>
																	<option value="0">Select Weapon reach</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.Weapon_reachError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                          <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Rarity
                                                            </label>
                                                            <div className="col-md-12">
                                                             <select name='Rarity' className="form-control" id='Rarity' onChange={inputHandler}>
																	<option value="">Select Rarity</option>
                                                                    <option value="Common">Common</option>
                                                                    <option value="Epic">Epic</option>
                                                                    <option value="Legendary">Legendary</option>
                                                                    <option value="Rare">Rare</option>
                                                                    <option value="Unique">Unique</option>
                                                                 </select>
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.RarityError}
                                                            </span> 
                                                        </div>
                                                         </div>
                                                         <div className="col-md-6 dpnone">
                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Rarity Weightage
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='Rarity_Weightage' className="form-control" id='Rarity_Weightage' onChange={inputHandler}>
                                                                    <option value="0">Select Rarity Weightage</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5">5</option>
                                                                    <option value="6">6</option>
                                                                    <option value="7">7</option>
                                                                    <option value="8">8</option>
                                                                    <option value="9">9</option>
                                                                    <option value="10">10</option>
                                                                 </select>
                                                            </div> 
                                                             <span className="validationErr">
                                                                {validatioError.Rarity_WeightageError}
                                                            </span>                                                     
                                                        </div>
                                                         </div>
                                                         
                                                         
                                                         <div className="col-md-6">

                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Price(SIN)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='price' id='priceError' className="form-control" onChange={inputHandler} type="text" placeholder="Enter Price (SIN)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.priceError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         
                                                         <div className="col-md-6 dpnone">
                                                        
                                                         <div className="form-group row mb-1 dpnone">
                                                            <label className="col-form-label col-md-12">
                                                                Price(BNB)
                                                            </label>
                                                            <div className="col-md-12">
                                                                <input name='bnb_price' id='bnb_priceError' className="form-control" onChange={inputHandler} type="text" placeholder="Enter Price (BNB)" onKeyPress={(event) => { if (!/^\d*[]?\d{0,1}$/.test(event.key)) { event.preventDefault(); } }} />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.bnb_priceError}
                                                            </span>
                                                        </div>
                                                        </div>
                                                        

                                                        {/* <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Sell Type
                                                            </label>
                                                            <div className="col-md-12">
                                                                <select name='sell_type' className="form-control" id='sellType' onChange={inputHandler}>
                                                                    <option value="1">Price</option>
                                                                    <option value="2">Auction</option>
                                                                </select>
                                                            </div>
                                                        </div>

                                                        {nftdata.sell_type == 2 ?
                                                            <>
                                                                <div className="form-group row mb-1">
                                                                    <label className="col-form-label col-md-12">
                                                                        Start Date
                                                                    </label>
                                                                    <DatePicker onChange={handleChangeStartDate} minDate={currentDate} autoComplete="off" name="start_date" id="startDateError" className="form-control" value={nftdata.start_date} />
                                                                    <span className="validationErr">{validatioError.startDateError}</span>
                                                                </div>

                                                                <div className="form-group row mb-1">
                                                                    <label className="col-form-label col-md-12">
                                                                        Expiry Date
                                                                    </label>
                                                                    <DatePicker onChange={handleChangeExpiryDate} minDate={currentDate} value={nftdata.expiry_date} autoComplete="off" id="expiryDateError" name="expiry_date" className="form-control" />
                                                                    <span className="validationErr">{validatioError.expiryDateError}</span>
                                                                </div></>
                                                            : ""} */}
                                                             
                                                         <div className="col-md-6">

                                                        <div className="form-group row mb-1">
                                                            <label className="col-form-label col-md-12">
                                                                Image
                                                            </label>
                                                            <div className="col-md-12">
                                                                {image_preview ?
                                                                    <>
                                                                        <img className='nftImg' src={image_preview} />
                                                                        <br />
                                                                    </>
                                                                    :
                                                                    ""
                                                                }
                                                                <span className="filename">PNG, JPG, GIF, WEBP</span>
                                                                <input onChange={imageUpload} type="file" className="inputfile form-control" name="file" />
                                                            </div>
                                                            <span className="validationErr">
                                                                {validatioError.imageError}
                                                            </span>
                                                        </div>
                                                         </div>
                                                         <div className="col-md-12">

                                                        <br />
                                                        <div className="text-center pull-left">
                                                            {spinloader == '0' ?
                                                                <button className='btn btn-primary' >Submit</button>
                                                                :
                                                                <button disabled className='btn btn-primary' >Creating NFT <i className="fa fa-spinner fa-spin validat"></i></button>
                                                            }
                                                        </div>
                                                         </div>
                                                    </div>                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* /.content */}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
};
export default Addcategory;
