import React, { useEffect, useState } from 'react'
import Header from '../directives/header';
import Sidebar from '../directives/sidebar';
import { getsettingsListAction, updatesettingsListsAction } from '../Action/action';
import toast, { Toaster } from 'react-hot-toast';

const Settings = () => {
    const [settingsList, setsettingsList] = useState([]);

    useEffect(() => {
        getsettingsListAPI()
    }, []);


    const getsettingsListAPI = async () => {
        let res = await getsettingsListAction({});
        if (res.success) {
            setsettingsList(res.data);
        }
    };

    const inputHandler = (e) => {
        const { name, value } = e.target
        setsettingsList((old) => {
            return { ...old, [name]: value }
        })
    }

    const updatesettings = async (e) => {
        e.preventDefault()

        let res = await updatesettingsListsAction(settingsList);
        if (res.success) {
            toast.success(res.msg);
        } else {
            toast.error(res.msg);
        }
    }

    return (

        <>
            <div class="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                    <div className="container-full">
                        {/* Main content */}
                        <div className="content-header">
                            <div className="align-items-center">
                                <div className="me-auto">
                                    <h3 className="page-title mb-5 pb-2">Settings </h3>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <section className="content">
                            <div className='box profile' >
                                <div className="row">
                                    <div className='col-lg-2'></div>
                                    <div className="col-lg-8  col-12">
                                        <form className="pt-3 profile">
                                            <div className="form-group">
                                                <label>1 SIN to Gold coin</label>
                                                <input type="text" onChange={inputHandler} value={settingsList.sin_to_coin_value} name="sin_to_coin_value" className="form-control input-profile p-1" id="exampleInputEmail1" />
                                            </div>
                                            <div className="form-group">
                                                <label>Whitelisted email addresses</label>
                                                 <textarea
													className="form-control"
													type="text"
													name="whitelist_emails"
													id='whitelist_emailsError'
													onChange={inputHandler} value={settingsList?.whitelist_emails}
													placeholder="Enter Whitelisted emails"/>
                                            </div>
                                             <div className="form-group">
                                                <label>Weapon on sale ?</label>
                                                  <select name='is_weapon_on_sale' className="form-control" id='is_weapon_on_saleError' onChange={inputHandler} value={settingsList?.is_weapon_on_sale}>
													<option value="0">No</option>
													<option value="1">Yes</option>
													</select>
                                            </div> 
                                            <div className="form-group">
                                                <label>Safe on sale ?</label>
                                                  <select name='is_safe_on_sale' className="form-control" id='is_safe_on_saleError' onChange={inputHandler} value={settingsList?.is_safe_on_sale}>
													<option value="0">No</option>
													<option value="1">Yes</option>
													</select>
                                            </div> 
                                            <div className="form-group">
                                                <label>Land on sale ?</label>
                                                  <select name='is_land_on_sale' className="form-control" id='is_land_on_saleError' onChange={inputHandler} value={settingsList?.is_land_on_sale}>
													<option value="0">No</option>
													<option value="1">Yes</option>
													</select>
                                            </div> 
                                            <div className="form-group">
                                                <label>License on sale ?</label>
                                                  <select name='is_license_on_sale' className="form-control" id='is_license_on_saleError' onChange={inputHandler} value={settingsList?.is_license_on_sale}>
													<option value="0">No</option>
													<option value="1">Yes</option>
													</select>
                                            </div>                                          
                                            <div className="form-group">
                                                <label>Whitelist wallet on/off</label>
                                                  <select name='whitelist_wallet_on_off' className="form-control" id='whitelist_wallet_on_offError' onChange={inputHandler} value={settingsList?.whitelist_wallet_on_off}>
													<option value="0">Off</option>
													<option value="1">On</option>
													</select>
                                            </div>                                          
                                              <div className="form-group">
                                                <label>Whitelisted wallet addresses</label>
                                                 <textarea
													className="form-control"
													type="text"
													name="whitelist_wallets"
													id='whitelist_walletsError'
													onChange={inputHandler} value={settingsList?.whitelist_wallets}
													placeholder="Enter Whitelisted wallet addresses"/>
                                            </div> 
											 <div className="form-group">
                                                <label>Purchase limit</label>
                                                <input type="text" onChange={inputHandler} value={settingsList.purchase_limit} name="purchase_limit" className="form-control input-profile p-1" id="exampleInputEmail1" />
                                            </div>
                                            
                                            <div className="mt-3">
                                                <a className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn pull-left" onClick={updatesettings} >Update</a>
                                            </div>
                                        </form>
                                    </div>
                                    <div className='col-lg-2'></div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Settings;
